import { isAndroid } from 'react-device-detect'

interface ENV {
  EXTENSION_ID: 'ljejldpaadpcghnfofhampphcmfmpenh'
  COOKIE_DOMAIN: string
  COOKIE_SECURE: boolean
  SELF_PROTCOL: 'http' | 'https'
  SELF_DOMAIN: string
  SELF_PORT: string
  API_PROTCOL: 'http' | 'https'
  API_DOMAIN: string
  API_PORT: string
  AUTH_PROTCOL: 'http' | 'https'
  AUTH_DOMAIN: string
  AUTH_PORT: string
}

const DEV: ENV = {
  EXTENSION_ID: 'ljejldpaadpcghnfofhampphcmfmpenh',
  COOKIE_DOMAIN: '.lvh.me',
  COOKIE_SECURE: false,
  SELF_PROTCOL: 'http',
  SELF_DOMAIN: 'lvh.me',
  SELF_PORT: '5000',
  API_PROTCOL: 'http',
  API_DOMAIN: isAndroid ? '10.0.2.2' : '127.0.0.1',
  API_PORT: '3000',
  AUTH_PROTCOL: 'http',
  AUTH_DOMAIN: 'lvh.me',
  AUTH_PORT: '3003',
}

const STG: ENV = {
  EXTENSION_ID: 'ljejldpaadpcghnfofhampphcmfmpenh',
  COOKIE_DOMAIN: '.stg.auth.locked.jp',
  COOKIE_SECURE: false,
  SELF_PROTCOL: 'http',
  SELF_DOMAIN: 'stg.auth.locked.jp',
  SELF_PORT: '80',
  API_PROTCOL: 'https', // apiはstgもhttps
  API_DOMAIN: 'stg.locked.jp',
  API_PORT: '443',
  AUTH_PROTCOL: 'http',
  AUTH_DOMAIN: 'stg.auth.locked.jp',
  AUTH_PORT: '80',
}

const PROD: ENV = {
  EXTENSION_ID: 'ljejldpaadpcghnfofhampphcmfmpenh',
  COOKIE_DOMAIN: '.auth.locked.jp',
  COOKIE_SECURE: true,
  SELF_PROTCOL: 'https',
  SELF_DOMAIN: 'auth.locked.jp',
  SELF_PORT: '443',
  API_PROTCOL: 'https',
  API_DOMAIN: 'locked.jp',
  API_PORT: '443',
  AUTH_PROTCOL: 'https',
  AUTH_DOMAIN: 'auth.locked.jp',
  AUTH_PORT: '443',
}

const TEST: ENV = {
  EXTENSION_ID: 'ljejldpaadpcghnfofhampphcmfmpenh',
  COOKIE_DOMAIN: '.lvh.me',
  COOKIE_SECURE: false,
  SELF_PROTCOL: 'http',
  SELF_DOMAIN: 'lvh.me',
  SELF_PORT: '5000',
  API_PROTCOL: 'http',
  API_DOMAIN: isAndroid ? '10.0.2.2' : '127.0.0.1',
  API_PORT: '3000',
  AUTH_PROTCOL: 'http',
  AUTH_DOMAIN: 'lvh.me',
  AUTH_PORT: '3003',
}

type CurrentEnv = 'development' | 'staging' | 'production' | 'test' | undefined
const currentEnv = (process.env.GATSBY_ACTIVE_ENV ||
  process.env.NODE_ENV) as CurrentEnv

export const env = (() => {
  if (process.env.GATSBY_ACTIVE_ENV === 'e2e') return DEV

  const env = currentEnv
  switch (env) {
    case 'development':
      return DEV
    case 'staging':
      return STG
    case 'production':
      return PROD
    case 'test':
      return TEST
    default:
      throw new Error(
        `different GATSBY_ACTIVE_ENV: ${process.env.GATSBY_ACTIVE_ENV}`,
      )
  }
})()
