import Cookies from 'js-cookie'
import { isIE } from 'react-device-detect'
import {
  tenMinutes,
  fiveYears,
  oneDay,
  oneDayMinutes,
} from 'scripts/jsCookieExpireMinutes'
import { FingerPrintHash } from 'types/FingerPrint'
import { env } from './env'

const get = (name: string) => {
  return Cookies.get(name)
}

// ie not allow secure
const set = (
  name: string,
  value: string,
  options?: Cookies.CookieAttributes,
) => {
  if (options?.secure && isIE) {
    delete options?.secure
  }
  Cookies.set(name, value, options)
}

const remove = (name: string, options?: Cookies.CookieAttributes) => {
  if (options?.secure && isIE) {
    delete options?.secure
  }
  Cookies.remove(name, options)
}

const secure = env.COOKIE_SECURE
const domain = env.COOKIE_DOMAIN

const lockedDeviceUuidOptions = {
  expires: fiveYears,
  secure,
  domain,
}

const lockedAuthSubdomainOptions = {
  expires: fiveYears,
  secure,
  domain,
}

const generateSessionTokenExpires = (sessionTokenExpiredAtMinutes: number) => {
  return oneDay / (oneDayMinutes / sessionTokenExpiredAtMinutes)
}

const setDeviceUuidToCookie = (uuid: string) => {
  set('_locked_device_uuid', uuid, lockedDeviceUuidOptions)
}

const setLockedEventIdToCookie = (eventId: string) => {
  set('_locked_event_id', eventId, {
    expires: tenMinutes,
    secure,
  })
}

const setLockedHashToCookie = (fingerprintHash: FingerPrintHash) => {
  const hash = get('_locked_hash')
  if (!hash) {
    set('_locked_hash', fingerprintHash as string, {
      expires: fiveYears,
      secure,
      domain,
    })
  }
}

const setSessionTokenToCookie = (sessionToken: string, expires: number) => {
  set('session_token', sessionToken, {
    domain,
    expires,
    secure,
  })
}

const setAuthSubdomainToCookie = (subdomain: string) => {
  set('_locked_auth_subdomain', subdomain, lockedAuthSubdomainOptions)
}

const removeSessionToken = () => {
  remove('session_token', { domain })
}

const removeLockedEventId = () => {
  remove('_locked_event_id')
}

export const cookies = {
  get,
  setDeviceUuidToCookie,
  setLockedEventIdToCookie,
  setLockedHashToCookie,
  setSessionTokenToCookie,
  generateSessionTokenExpires,
  removeSessionToken,
  removeLockedEventId,
  setAuthSubdomainToCookie,
}
